



















































































import { Component, Vue } from 'vue-property-decorator';
import Header from '@/components/header.vue'; // @ is an alias to /src
import Loader from '@/components/loader.vue'; // @ is an alias to /src
import administration from '@/components/administration.vue'; // @ is an alias to /src
import administrationheader from '@/components/administrationheader.vue'; // @ is an alias to /src
import BuiltinService from '@/services/buitin-service';

@Component({
  components: {
    Header,
    Loader,
    administration,
    administrationheader
  },
})
export default class DesignationBuiltin extends Vue {
  public fields = [{key: 'title', sortable: true }, {key: 'actions', label: 'Actions'}];
  error_mesg = null; 
  filter = null;
 loading = true;
   filterOn = [];
  public items= [];

  private designation: any = { title: ""};

public currentPage = 1;
get rows(){return this.items.length;}
  retrieve() {
     this.loading = true;
    BuiltinService.getdesignation().then((response) => {
        this.items = response.data;
        this.loading = false;
      })
      .catch((e) => {
        this.loading = false;
        console.log(e);
      });
  }

  handleSubmit(){

    this.create();
  }
  create() {
     this.loading = true;
     this.error_mesg = null;
    if(this.designation['id'] != null){
    BuiltinService.putdesignation(this.designation['id'], this.designation)
      .then((response) => {
        if(response){
          this.retrieve();
          this.designation = {title : ""}
         this.loading = false;
        }
      })
      .catch((e) => {
        this.loading = false;
        console.log(e);
      });
     }else{
    
    BuiltinService.postdesignation(this.designation)
      .then((response) => {
        if(response){
          this.retrieve();
          this.designation = {title : ""}
         this.loading = false;
        }
      })
      .catch((e) => {
        this.loading = false;
        console.log(e);
      });
     }
  }
    onFiltered(filteredItems:any) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      // this.rows = filteredItems.length
      // this.currentPage = 1
    }
     delete(id: any){
     BuiltinService.deletedesignation(id)
      .then((response) => {
        if(response){
          this.retrieve();
          this.designation = {id: null, title : ""}
            this.loading = false;
        }
      })
      .catch((e) => {
        let eror = e.response.data;
        let r = eror[Object.keys(eror)[0]];
        this.error_mesg = r;
          this.loading = false;
      });
  }

  info(row: any){
    this.designation['id'] = row['id']; 
    this.designation['title'] = row['title'];
    this.designation['user'] = row['user'];
  }
  removeinfo(id: any){
    this.delete(id);
  }    
  mounted() {
  this.retrieve();
  }
}
